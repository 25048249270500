<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button v-show="useDetail.flowStateCode === 'Completed'" v-loading="loading.export" type="success" @click="exportFile">
          导出
        </el-button>
        <el-button @click="$router.back()">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="用信进度">
          <el-col :span="24">
            <el-steps :active="stepActiveIndex" finish-status="success">
              <el-step title="申请" />
              <el-step title="补件" />
              <el-step title="资方审批" />
              <el-step title="资方放款" />
              <el-step title="还款" />
              <el-step title="完成" />
            </el-steps>
          </el-col>
        </ics-page-inner>
        <div class="tabs-inner">
          <el-tabs v-model="activeName" type="card" @tab-click="tabClickRoleList()">
            <el-tab-pane label="用信信息" name="use">
              <ics-page-inner title="用信信息">
                <el-col :span="12">
                  <el-form-item label="用信单号">
                    <p>{{ utils.isEffectiveCommon(useDetail.useCode) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="申请方">
                    <p>{{ utils.isEffectiveCommon(useDetail.customerName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="申请金额(元)">
                    <p>{{ utils.moneyFormat(useDetail.applyAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.passAmount" :span="12">
                  <el-form-item label="批复金额(元)">
                    <p>{{ utils.moneyFormat(useDetail.passAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner title="应收账款信息">
                <el-col :span="12">
                  <el-form-item label="凭证编号">
                    <p>{{ utils.isEffectiveCommon(payableInfoVo.sfNo) }}</p>
                  </el-form-item>
                </el-col>
                <el-col />
                <el-col :span="12">
                  <el-form-item label="应收金额">
                    <p>{{ utils.moneyFormat(payableInfoVo.amount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="应收期限">
                    <p>{{ utils.dateFormat(payableInfoVo.paymentTerm) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="!_.isEmpty(payableInfoVo.filesList)" :span="12">
                  <el-form-item label="凭证文件">
                    <p v-for="(item, index) in payableInfoVo.filesList" :key="index">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('other', item.url)">{{ item.name }}</a>
                    </p>
                  </el-form-item>
                </el-col>
                <el-col v-if="_.isEmpty(payableInfoVo.filesList)" :span="12">
                  <el-form-item label="凭证文件">
                    <p>-</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner title="发票信息">
                <el-col :span="12">
                  <el-form-item label="发票信息">
                    <a href="javascript:" class="text-btn" @click="clickDialog('invoices')">点击查看</a>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner v-if="stepActiveIndex > 1" title="付款计划信息">
                <el-col :span="12">
                  <el-form-item label="付款计划信息">
                    <p v-for="(item, index) in voucherFilesList" :key="index">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('use', item.url)">{{ item.name }}</a>
                    </p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner title="授信信息">
                <el-col :span="24">
                  <el-form-item label="关联授信">
                    <p>{{ utils.isEffectiveCommon(creditDetail.credit_code) }}</p>
                  </el-form-item>
                </el-col>
                <el-col />
                <el-col :span="12">
                  <el-form-item label="资方">
                    <p>{{ utils.isEffectiveCommon(creditDetail.cap_name) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="授信期限">
                    <p>{{ creditDetail.effective_start===undefined ? '' : (creditDetail.effective_start + ' 至 ' + creditDetail.effective_end) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="总授信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.pass_amount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="可用授信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.available_aomunt, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="已用信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.loan_amount, 2) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-product-inner :product-detail="productDetail" />
              <ics-page-inner v-if="JSON.stringify(description) !== '{}'" title="回款信息">
                <el-col :span="12">
                  <el-form-item label="回款企业类型">
                    <p>{{ utils.statusFormat(description.type, 'collectionCompanyType') }}</p>
                  </el-form-item>
                </el-col>
                <el-col />
                <el-col :span="12">
                  <el-form-item label="资金方企业账户">
                    <p>{{ utils.isEffectiveCommon(description.capAccount) }}</p>
                  </el-form-item>
                </el-col>
                <el-col />
                <el-col :span="12">
                  <el-form-item label="融资方企业账户">
                    <p>{{ utils.isEffectiveCommon(description.customerAccount) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner v-if="stepActiveIndex > 3" title="放款信息">
                <el-col v-if="useDetail.loanAmount" :span="12">
                  <el-form-item label="放款金额">
                    <p>{{ utils.moneyFormat(useDetail.loanAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.paymentAmount" :span="12">
                  <el-form-item label="付款金额">
                    <p>{{ utils.moneyFormat(useDetail.paymentAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="method !== 0 && useDetail.loanBank" :span="12">
                  <el-form-item label="放款银行">
                    <p>{{ utils.isEffectiveCommon(useDetail.loanBank) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.loanAccount" :span="12">
                  <el-form-item label="放款账户">
                    <p>{{ utils.isEffectiveCommon(useDetail.loanAccount) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.loanAt" :span="12">
                  <el-form-item label="放款日期">
                    <p>{{ utils.dateFormat(useDetail.loanAt) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.useEnd" :span="12">
                  <el-form-item label="到期日">
                    <p>{{ utils.dateFormat(useDetail.useEnd) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="useDetail.useDay" :span="12">
                  <el-form-item label="用款期限">
                    <p>{{ utils.isEffectiveCommon(useDetail.useDay) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="!_.isEmpty(loanFilesList)" :span="12">
                  <el-form-item label="放款凭证">
                    <p v-for="(item, index) in loanFilesList" :key="index">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('use', item.url)">{{ item.name }}</a>
                    </p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner title="收款信息">
                <el-col :span="12">
                  <el-form-item label="收款人">
                    <p>{{ utils.isEffectiveCommon(bankFilesInfo.firmName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col v-if="method !== 0" />
                <el-col v-if="method !== 0" :span="12">
                  <el-form-item label="收款银行">
                    <p>{{ utils.isEffectiveCommon(bankFilesInfo.bankName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="收款账户">
                    <p>{{ utils.isEffectiveCommon(bankFilesInfo.bankAccount) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
            </el-tab-pane>
            <el-tab-pane label="操作记录" name="operating">
              <div class="partition-table">
                <el-table :data="operatingList" border style="width: 100%" class="table-input" max-height="350">
                  <el-table-column prop="operationTypeName" label="操作类型" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                  <el-table-column prop="content" label="操作内容" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                  <el-table-column prop="operationAnnotation" label="备注" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="operationName" label="操作人" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="recordTime" label="操作时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.invoice.visible" :title="dialog.invoice.title" cancel-title="返回" class="form-dialog" :show-submit="false">
      <div class="partition-table">
        <el-table :data="invoicesList" border style="width: 100%" class="table-input" max-height="250">
          <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
          <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
          <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">下载</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import IcsProductInner from './components/product-inner'
export default {
  components: {IcsDialogInner, IcsProductInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      useCode: this.$route.query.useCode,
      taskId: this.$route.query.taskId,
      activeName: 'use',
      stepActiveIndex: 0,
      appForm: {
        loanBank: '',
        loanAccount: '',
        loanAt: '',
        loanAmount: '',
        amount: '',
        files: []
      },
      method: '',
      bankKey: 0,
      productKey: 0,
      useDetail: {},
      creditDetail: {},
      contractBaseInfo: {},
      description: {},
      payableInfoVo: {},
      productDetail: {},
      bankFilesInfo: {},
      invoicesList: [],
      voucherFilesList: [],
      operatingList: [],
      loanFilesList: [],
      dialog: {
        invoice: {
          visible: false,
          title: '查看发票信息'
        }
      },
      rules: {}
    }
  },
  created() {
    if (this.useCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.loading.detail = true
      this.api.use.useDetails({useCode: this.useCode}).then(result => {
        const data = result.data.data || {}
        this.useDetail = data.use
        this.creditDetail = JSON.parse(data.use.creditSnapshot)
        if (data.use.description) {
          this.description = JSON.parse(data.use.description)
        }
        this.contractBaseInfo = data.credit.contractBaseInfo
        this.payableInfoVo = data.payableInfoVo
        const productJson = JSON.parse(data.credit.snapshot.productJson) || {}
        productJson.penalTYtRate = productJson.penaltytRate
        this.productDetail = productJson
        if (Number(productJson.repaymentMethod) === 1) {
          this.method = 1
        } else {
          this.method = 0
        }
        this.bankFilesInfo = JSON.parse(data.use.bankJson)
        data.files.forEach(item => {
          if (item.suffix === 'Voucher') {
            this.voucherFilesList.push(item)
          }
          if (item.suffix === 'LoanFile') {
            this.loanFilesList.push(item)
          }
        })
        switch (data.use.flowStateCode) {
          case 'Wait':
            this.stepActiveIndex = 0
            break
          case 'UseSupplement':
            this.stepActiveIndex = 1
            break
          case 'UseAudit':
            this.stepActiveIndex = 2
            break
          case 'UseAmount':
            this.stepActiveIndex = 3
            break
          case 'Repaid':
            this.stepActiveIndex = 4
            break
          case 'Fail':
            this.stepActiveIndex = 6
            break
          case 'Completed':
            this.stepActiveIndex = 6
            break
          default:
            this.stepActiveIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    tabClickRoleList () {
      if (this.activeName === 'operating') {
        this.getOperatingList()
      }
    },
    getOperatingList () {
      const data = {
        businessId: this.useCode,
        pageNum: 1,
        pageSize: 99999
      }
      this.api.operating.listPage(data).then(result => {
        this.operatingList = result.data.data.list
      }).finally(() => {
      })
    },
    clickDialog (type) {
      if (type === 'invoices') {
        this.invoicesList = this.payableInfoVo.invoicesList || []
        this.dialog.invoice.visible = true
      }
    },
    exportFile () {
      this.loading.export = true
      this.api.use.theCodeExportFile(this.useCode).then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    }
  }
}
</script>

<style scoped>

</style>
